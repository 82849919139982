.BarChart {
    background-color: #eeebeb;
    border-radius: 3px;
    display: flex;
    height: 40px;
    width: 95%;
    overflow:hidden;
    margin-left: 25px;
}

.BarData {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PercentText {
    color: #eeebeb;
    text-align: center;
    margin: auto;
}
